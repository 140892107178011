<template>
  <date-picker
    v-bind="$attrs"
    :value="value"
    :lang="currentLanguage"
    @change="onChange"
  >
    <template #icon-calendar>
      <svg-icon name="calendar" />
    </template>
  </date-picker>
</template>

<script>
import DatePicker from 'vue2-datepicker';

export default {
  components: {
    DatePicker,
  },
  props: {
    value: {
      type: [Date, String],
      default: null,
    },
  },
  computed: {
    currentLanguage() {
      return this.$i18n.locale;
    },
  },
  mounted() {
    switch (this.$i18n.locale) {
      case 'de':
        require('vue2-datepicker/locale/de');
        break;
      case 'fr':
        require('vue2-datepicker/locale/fr');
        break;
      case 'it':
        require('vue2-datepicker/locale/it');
        break;
    }
  },
  methods: {
    onChange(value) {
      this.$emit('input', value);
    },
  },
};
</script>
